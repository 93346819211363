//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Breadcrumbs',
  data () {
    return {
      items: [
        {
          text: 'Admin',
          href: '#'
        }, 
        {
          text: 'Manage',
          href: '#'
        }, 
        {
          text: 'Library'
        }
      ],


      items2: [
        {
          text: 'Go to dashboard',
          to: '/dashboard'
        }, 
        {
          text: 'Go to widgets',
          to: '/Widgets'
        }, 
        {
          text: 'Go to Google',
          href: 'http://google.com'
        },
        {
          text: 'Current page'
        }
      ],


      items3: [
        {
          text: 'Added',
          to: '#2',
          addClasses: 'font-xl'
        }, 
        {
          text: 'Custom',
          to: '#3',
          addClasses: 'font-xl'
        },
        {
          text: 'Classes',
          to: '#4',
          addClasses: 'font-xl text-danger'
        }
      ]
    }
  }
}
